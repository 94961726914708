import {
    CartProduct,
    CombinedDiscount,
    Discount,
    DISCOUNT_TYPE,
    FixedDiscount,
    MenuCategoryProducts,
    OnlineFixedDiscount,
    OnlineMenu,
    OrderWindow,
    RefProduct
} from "Types";
import {
    getDiscountedProductIdsFromCartProducts,
    getCombinedDiscountedProductIds,
    isSubscriptionFixedDiscounts
} from "./../../../pages/checkout/components/OnlineCheckoutForm/utils";

/**@deprecated This is only re-exported here from "Utils".  Please use "Utils" directly */
export { hasFullMenuFixedDiscount, hasProductOrCategoryFixedDiscount } from "Utils";

export type DiscountDisplay = {
    id: string;
    value: number;
    name?: string;
    rate?: number;
    amount?: number;
    message: string | null;
    isCombinedDiscount: boolean;
    isSubscriptionDiscount: boolean;
};

/** NOTE: in test suite */
/**
 * Get combined array of discounts from orderWindow
 * @param {OrderWindow} orderWindow
 * @returns {(Discount | FixedDiscount)[]} mixed array of fixed discounts and discount from orderWindow
 */
export const getOrderWindowDiscounts = (orderWindow: OrderWindow | undefined) => {
    let discounts: (Discount | FixedDiscount)[] = [];

    if (!!orderWindow?.fixedDiscounts) {
        discounts = [...orderWindow.fixedDiscounts];
    }

    if (!!orderWindow?.discount) {
        discounts = [...discounts, orderWindow.discount];
    }

    return discounts;
};

/** NOTE: in test suite */
/**
 * Function which merged duplicated discounts within cartProducts
 * @param {DiscountDisplay[]} discountsToDisplay
 * @returns {DiscountDisplay[]} merged array of discounts. If duplicated fixedDiscounts across cartProducts, we merge their values
 */
export const mergeOnlineDiscounts = (discountsToDisplay: DiscountDisplay[]) => {
    return discountsToDisplay.reduce((allDiscounts: DiscountDisplay[], discountToDisplay: DiscountDisplay) => {
        const index = allDiscounts.findIndex((discount: DiscountDisplay) => discount.id === discountToDisplay.id);

        if (index !== -1) {
            allDiscounts[index].value += discountToDisplay.value;
        } else {
            allDiscounts.push(discountToDisplay);
        }
        return allDiscounts;
    }, []);
};

/**
 * Function which merge combined discounts into one singular
 * @param {OrderWindow} orderWindow
 * @returns {DiscountDisplay} singular discount with a combined discounted value
 */
const getAppliedDiscount = (orderWindow: OrderWindow): DiscountDisplay => {
    const totalValue = orderWindow.cartProducts.reduce((total: number, cartProduct) => {
        const { orderProduct } = cartProduct;
        if (orderProduct.combinedDiscounts && orderProduct.combinedDiscounts?.length > 0) {
            const discountValue = orderProduct.combinedDiscounts.reduce(
                (discount: number, combined: CombinedDiscount) => {
                    if (combined.discountId === orderWindow.discount.id) {
                        discount += combined.discountValue;
                    }
                    return discount;
                },
                0
            );
            total += discountValue;
        }
        return total;
    }, 0);

    return {
        id: orderWindow.discount.id,
        value: totalValue,
        name: orderWindow.discount.name,
        message: orderWindow.discount.onlineCustomerMessage ?? null,
        isCombinedDiscount: false,
        isSubscriptionDiscount: false
    };
};

/**
 * Function which find the combinedDiscount from a cartProduct which has fixedDiscount
 * @param {CombinedDiscount[]} combinedDiscounts
 * @param {OnlineFixedDiscount} fixedDiscount
 * @returns
 */
const getCombinedDiscount = (
    combinedDiscounts?: CombinedDiscount[],
    fixedDiscount?: OnlineFixedDiscount
): DiscountDisplay => {
    const foundCombinedDiscount = combinedDiscounts?.find(combinedDiscount => combinedDiscount.discountOrder === 1);

    if (!foundCombinedDiscount) {
        return {
            id: "",
            value: 0,
            message: null,
            isCombinedDiscount: false,
            isSubscriptionDiscount: false
        };
    } else {
        const isSubscriptionDiscount = foundCombinedDiscount.discountType === DISCOUNT_TYPE.SUBSCRIPTION_DISCOUNT;

        return {
            id: foundCombinedDiscount.discountId,
            value: foundCombinedDiscount.discountValue,
            name: foundCombinedDiscount.name,
            rate: (foundCombinedDiscount.discountRate ?? 0) * 100,
            amount: fixedDiscount?.subscriptionProductMeta?.amountDiscount,
            message: null,
            isCombinedDiscount: true,
            isSubscriptionDiscount
        };
    }
};

/** NOTE: in test suite */
/**
 * Function to get all discounts from cartProducts and orderWindow discount
 * @param {OrderWindow} orderWindow
 * @returns {DiscountDisplay[]} Combined list of all discounts from cartProducts and discount on orderWindow itself
 */
export const getAllDiscountValues = (orderWindow: OrderWindow | undefined) => {
    let discountDisplays =
        orderWindow?.cartProducts.reduce((values: DiscountDisplay[], cartProduct) => {
            const discountIds: string[] = cartProduct.orderProduct?.discountIds ?? [];
            if (discountIds) {
                const discountBreakdown = discountIds
                    .filter(value => {
                        return value !== orderWindow.discount?.id;
                    })
                    .map((id: string) => {
                        return {
                            ...getCombinedDiscount(
                                cartProduct.orderProduct.combinedDiscounts,
                                cartProduct.fixedDiscount
                            ),
                            id
                        } as DiscountDisplay;
                    });
                return [...values, ...discountBreakdown];
            }
            return values;
        }, [] as DiscountDisplay[]) ?? [];

    if (!!orderWindow?.discount) {
        const appliedDiscountValue = getAppliedDiscount(orderWindow);
        discountDisplays = [...discountDisplays, appliedDiscountValue];
    }

    return discountDisplays;
};

/**
 * [FUNCTION]
 * * Takes the discount and checks the categories against the menu to get all product ids
 * that are under that menu category
 * * Used to fix a bug in search as the category id in search is not and actual menu category
 * @param onlineMenu
 * @param selectedActiveMenuId
 * @param activeFixedDiscount
 * @returns
 */
export const transformFixedDiscountCategoryToProductIds = (
    activeFixedDiscount: FixedDiscount,
    onlineMenu: OnlineMenu | null,
    selectedActiveMenuId: string | null
) => {
    /** Get Active fixed discount menu / category & product ids */
    const activeMenuHasFixedDiscount = (activeFixedDiscount || {})?.menuCategoryAndProductIds?.find(
        (value: MenuCategoryProducts) => value.menuId === selectedActiveMenuId
    ) as MenuCategoryProducts;

    /** Get Categories in onlineMenu */
    const menuCategories = onlineMenu?.onlineProductCategories?.filter(cat =>
        activeMenuHasFixedDiscount?.categoryIds?.includes(cat.id)
    );

    /** If no categories in the active discount return discount */
    if (!menuCategories?.length) {
        return activeFixedDiscount;
    }

    /** Get all product ids in categories */
    const productIds = menuCategories.flatMap(cat => cat?.onlineProducts?.map(prod => prod.id));

    /** Add product ids to discount mapping so product ids from category into product id array */
    const adjustedMenuCategoryProductIds = activeFixedDiscount?.menuCategoryAndProductIds.map(
        (cat: MenuCategoryProducts) => {
            if (productIds?.length && cat.menuId === selectedActiveMenuId) {
                return {
                    ...cat,
                    productIds: [...cat.productIds, ...productIds]
                };
            }
            return cat;
        }
    ) as MenuCategoryProducts[];

    return { ...activeFixedDiscount, menuCategoryAndProductIds: adjustedMenuCategoryProductIds };
};

export const getDiscountedCartProductIds = (cartProducts: CartProduct[], discount: Discount) => {
    const isProductCategoryDiscount = !!discount?.menuCategoryAndProductIds?.length;

    const qoplaSponsoredOverrideCombine = discount?.qoplaSponsored ?? false;

    if (isProductCategoryDiscount) {
        const initialDiscountedCartProductIds = getDiscountedProductIdsFromCartProducts(
            cartProducts,
            discount?.menuCategoryAndProductIds ?? []
        );

        return getCombinedDiscountedProductIds(
            cartProducts,
            initialDiscountedCartProductIds,
            qoplaSponsoredOverrideCombine
        );
    } else {
        return cartProducts
            .filter((cartProduct: CartProduct) => {
                const canOverrideCombineDiscounts =
                    qoplaSponsoredOverrideCombine && !isSubscriptionFixedDiscounts(cartProduct);
                return (
                    canOverrideCombineDiscounts ||
                    (cartProduct.fixedDiscount && cartProduct.fixedDiscount.canCombineDiscounts) ||
                    !cartProduct.fixedDiscount
                );
            })
            .map((cartProduct: CartProduct) => cartProduct.id);
    }
};

/**
 * [FUNCTION] - Passes in cart products + discounted ids + bannedDiscountIds
 * - Will remove cart ids from discounted product ids
 * @param cartProducts
 * @param bannedRefProductIds
 * @param discountedCartProductIds
 * @returns
 */
export const removeBannedDiscountedProductIds = (
    cartProducts: CartProduct[],
    bannedRefProductIds: Set<string>,
    discountedCartProductIds: string[]
) => {
    const allowedCartProductIds = cartProducts
        .filter(
            cart => !bannedRefProductIds.has(cart.orderProduct?.refProductId ?? cart.orderProduct?.refBundleProductId!)
        )
        .map(cart => cart.id);
    return discountedCartProductIds.filter(id => allowedCartProductIds.includes(id));
};
