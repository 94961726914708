import flatMap from "lodash/flatMap";

import {
    CompanySubscriptionSettings,
    LocalSubscriptionMeta,
    Menu,
    OnlineMenus,
    SubscriptionProducts,
    Subscriptions,
    UserSubscriptionDTO,
    ProductsStock
} from "Types";
import { TranslateFunc } from "LanguageProvider";
import { getActiveMenuProductCategories } from "../../../../hooks/useActiveMenuProductCategories";
import { getOnlineProductCategories } from "OnlineUtils";
import { filterUpsellCategories } from "Filters";
import { getOnlineUpsellCategories } from ".";
import { getSubscriptionMenuProductCategories } from "../../../pages/onlineOrder/utils";

const splitFreeAndNonFreeSubscriptionProducts = (subscriptionProducts: SubscriptionProducts) => {
    return subscriptionProducts.reduce<{
        free: SubscriptionProducts;
        nonFree: SubscriptionProducts;
    }>(
        (acc, next) => {
            const isFree = next.percentageDiscount === 100;

            if (isFree) {
                acc.free.push(next);
            } else {
                acc.nonFree.push(next);
            }

            return acc;
        },
        { free: [], nonFree: [] }
    );
};

const splitFreeAndRegularUserSubscriptions = (userSubscriptions: UserSubscriptionDTO[]) => {
    return userSubscriptions.reduce<{
        free: UserSubscriptionDTO[];
        nonFree: UserSubscriptionDTO[];
    }>(
        (acc, next) => {
            const { free, nonFree } = splitFreeAndNonFreeSubscriptionProducts(next.subscription.subscriptionProducts);

            const _push = (key: "free" | "nonFree", products: SubscriptionProducts) => {
                acc[key].push({
                    ...next,
                    subscription: {
                        ...next.subscription,
                        subscriptionProducts: products
                    }
                });
            };

            _push("free", free);
            _push("nonFree", nonFree);

            return acc;
        },
        { free: [], nonFree: [] }
    );
};

export const transformMenusToOnlineMenus = (
    menus: Menu[],
    outOfStockIdsSet: Set<string>,
    hideFromStockIdsSet: Set<string>,
    productsIdsAndQuantity: { [id: string]: number },
    subscriptions: Subscriptions,
    userSubscriptions: UserSubscriptionDTO[],
    companySubscriptionSettings: CompanySubscriptionSettings | null,
    productsStock: ProductsStock,
    translate: TranslateFunc
): OnlineMenus => {
    const { free: freeUserSubscriptions, nonFree: nonFreeUserSubscriptions } =
        splitFreeAndRegularUserSubscriptions(userSubscriptions);

    const allNonFreeSubscriptionProducts: LocalSubscriptionMeta[] = flatMap(
        nonFreeUserSubscriptions,
        userSubscription =>
            userSubscription.subscription.subscriptionProducts.map(prod => ({
                ...prod,
                subscriptionId: userSubscription.subscription.id
            }))
    );

    return menus.map(({ allowTopSeller, menuProductCategories, ...menu }) => {
        const hasSubscriptions = !!subscriptions.length && !!companySubscriptionSettings;

        const _menuProductCategories = getActiveMenuProductCategories(menuProductCategories, hideFromStockIdsSet);

        let onlineProductCategories = getOnlineProductCategories(
            _menuProductCategories,
            outOfStockIdsSet,
            hideFromStockIdsSet,
            productsIdsAndQuantity,
            allNonFreeSubscriptionProducts,
            productsStock
        );

        if (hasSubscriptions) {
            const allProducts = flatMap(_menuProductCategories, ({ menuProducts, menuBundleProducts }) => [
                ...menuProducts,
                ...menuBundleProducts
            ]);

            const showAsEnrolledSubscriptions = !!userSubscriptions.length;

            const subscriptionMenuProductCategories = getSubscriptionMenuProductCategories(
                freeUserSubscriptions,
                allProducts,
                showAsEnrolledSubscriptions,
                translate
            );

            const _subscriptionMenuProductCategories = getActiveMenuProductCategories(
                subscriptionMenuProductCategories,
                hideFromStockIdsSet
            );

            const subscriptionOnlineProductCategories = getOnlineProductCategories(
                getActiveMenuProductCategories(_subscriptionMenuProductCategories, hideFromStockIdsSet),
                outOfStockIdsSet,
                hideFromStockIdsSet,
                productsIdsAndQuantity,
                allNonFreeSubscriptionProducts,
                productsStock
            );

            onlineProductCategories = [...subscriptionOnlineProductCategories, ...onlineProductCategories];
        }

        return {
            ...menu,
            ...getOnlineUpsellCategories(onlineProductCategories),
            onlineProductCategories: onlineProductCategories.filter(filterUpsellCategories)
        };
    });
};
